* {
  padding: 0;
  margin: 0;
}
.header {
  height: 45px;
  line-height: 45px;
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 9999;
  display: block;
  margin: 0;
  width: 100%;
  min-width: 1024px;
  height: 48px;
  max-height: 44px;
  background: rgba(221, 221, 221, 0.92);
  font-size: 17px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 60px;
}
.follow {
  float: right;
  margin-right: 79px;
}
.follow img {
  width: 25px;
  position: relative;
  top: 7px;
}

body {
  font-family: "Sen", sans-serif;
  background: #f9f9fa;
}

.chart-container {
  width: max-content;
  margin: 7% auto;
}

.follow a {
  color: #131313;
  text-decoration: none;
}

.org-tree ul {
  padding-top: 20px;
  position: relative;

  /* transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s; */
}

.org-tree li {
  float: left;
  text-align: center;
  list-style-type: none;
  position: relative;
  padding: 20px 5px 0 5px;

  /* transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s; */
}

/*We will use ::before and ::after to draw the connectors*/

.org-tree li::before,
.org-tree li::after {
  content: "";
  position: absolute;
  top: 0;
  right: 50%;
  border-top: 2px solid #bbc;
  width: 50%;
  height: 70px;
}
.org-tree li::after {
  right: auto;
  left: 50%;
  border-left: 2px solid #bbc;
}

.org-tree li:only-child::after,
.org-tree li:only-child::before {
  display: none;
}

.org-tree li:only-child {
  padding-top: 0;
}

.org-tree li:first-child::before,
.org-tree li:last-child::after {
  border: 0 none;
}

.org-tree li:last-child::before {
  border-right: 2px solid #bbc;
  border-radius: 0 5px 0 0;
  -webkit-border-radius: 0 5px 0 0;
  -moz-border-radius: 0 5px 0 0;
}
.org-tree li:first-child::after {
  border-radius: 5px 0 0 0;
  -webkit-border-radius: 5px 0 0 0;
  -moz-border-radius: 5px 0 0 0;
}

.org-tree ul ul::before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  border-left: 2px solid #bbc;
  width: 0;
  height: 20px;
}

.org-tree ul[class="singleChild"] ul[class="singleChild"]::before {
  height: 70px;
}

.org-tree li a {
  border: 1px solid #ccc;
  padding: 5px 10px;
  text-decoration: none;
  color: #666;
  font-family: arial, verdana, tahoma;
  font-size: 11px;
  display: inline-block;

  border-radius: 5px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;

  /* transition: all 0.5s;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s; */
}

.org-tree li a:hover,
.org-tree li a:hover + ul li a {
  background: #c8e4f8;
  color: #000;
  border: 1px solid #94a0b4;
}

.org-tree li a:hover + ul li::after,
.org-tree li a:hover + ul li::before,
.org-tree li a:hover + ul::before,
.org-tree li a:hover + ul ul::before {
  border-color: #94a0b4;
}

.card {
  position: relative;
  /* padding-bottom: 50px; */
  /* width: 300px; */
}

.card .image {
  border-radius: 50%;
  width: 190px;
  /* height: 80px; */
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: -10px;
  z-index: 100;
}
.card .image img {
  border-radius: 50%;
  width: 80px;
  height: 80px;
  border: 13px solid #96f6b7;
}
.card .card-body {
  background: transparent;
  /* height: 56px; */
  text-align: center;
  /* width: 300px; */
  padding-top: 60px;
  border-radius: 16px;
  /* border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; */
  position: relative;
  /* z-index: -1; */
  left: 50%;
  transform: translateX(-50%);
  border: 1px solid rgba(0, 0, 0, 0.12);
  cursor: pointer;
}

.card .card-body h2 {
  font-weight: 600;
  line-height: 20px;
}
.card .card-body h2 {
  font-weight: 600;
  line-height: 20px;
  margin-top: -18px;
}
.card .card-body p {
  line-height: 20px;
  font-size: small;
}
.card .card-footer {
  padding: 3px;
  background: #96f6b7;
  text-align: center;
  bottom: 0;
  position: relative;
  width: 295px;
  border-radius: 16px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  z-index: -1;
  left: 50%;
  transform: translateX(-50%);
}
.card .card-footer img {
  width: 25px;
  margin: 5px;
}

.SvgIcon-root {
  fill: currentColor;
  width: 1em;
  height: 1em;
  display: inline-block;
  font-size: 1.5rem;
  /* transition: fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
  flex-shrink: 0;
  user-select: none;
  cursor: pointer;
}

.SvgIcon-expand {
  transform: rotate(180deg);
  /* transition: transform 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms; */
}

.SvgIcon-close {
  transform: rotate(0deg);
}

.custom-btn {
  background-color: rgba(0, 0, 0, 1);
  /* background-color: rgb(9, 97, 220); */
  border-width: 1;
  border-color: rgba(9, 97, 220, 0.5);
  color: #fff;
  padding: 0.5rem;
  padding-left: 0.8rem;
  padding-right: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  font-size: 14px;
  font-weight: 600;
  border-radius: 4px;
  cursor: pointer;
  width: max-content;
}

.custom-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

html {
  overflow: hidden;
}

path.link[style*="stroke: rgb(140, 236, 254)"] {
  stroke-width: 5.5 !important;
}

.loader {
  border-top-color: #3498db;
  -webkit-animation: spinner 1.5s linear infinite;
  animation: spinner 1.5s linear infinite;
}

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Collapsible Closed */
details {
  margin: 1rem 1rem;
  padding: 1rem;
  background-color: #fff;
  border-radius: 0.5rem;
  box-shadow: 0 0.25rem 1rem rgba(0, 0, 0, 0.1);
}

details summary {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 600;
  margin-bottom: 0;
  transition: margin-bottom 0.4s;
  position: relative;
  cursor: pointer;
}

/* Disable browser default marker */
details summary::-webkit-details-marker,
details summary::marker {
  content: "";
  display: none;
}

/* Custom marker */
details summary::after {
  content: "+";
  font-size: 1.5rem;
  font-weight: 400;
  line-height: 1;
  margin-right: 1rem;
  cursor: pointer;
  background-color: rgba(12, 12, 12, 0.1);
  padding: 0.45rem;
  display: grid;
  place-content: center;
  aspect-ratio: 1;
  line-height: 0;
  position: absolute;
  top: -0.3rem;
  right: -1.2rem;
  border-radius: 50%;
}

details *:not(summary) {
  animation-name: fade;
  animation-duration: 0.4s;
}

/* Collapsible OPEN */
details[open] summary {
  margin-bottom: 1.5rem;
}

details[open] summary::after {
  content: "−";
}

@keyframes fade {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
